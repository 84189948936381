.masonryholder {
    /*margin: 10px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 4px;*/
    column-count: 4;
    column-gap: 20px;
    max-width: 1200px;
    /*margin: 0 auto;*/
}

.masonryblocks {
    display: inline-block;
    padding: 10px;
    margin: 0 0 10px;
    width: 100%;
    box-sizing: border-box;
}

.masonryblocks img {
    width: 100%;
    border-radius: 4px 4px 0 0;
}
.caption {
    background: #FE7820;
    margin-top: -6px;
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
}

.bl_masonryblocks {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    border-radius: 4px;
}

.bl_masonryblocks:hover {
    cursor: pointer;
    box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
    border-radius: 4px;
}

.imageTitle {
    font-size: 1.5rem;
}

@media screen and (max-width:768px) {
    .masonryholder {
        column-count: 1;
    }
}

@media screen and (min-width:769px) {
    .masonryholder {
        column-count: 2;
    }
}

@media screen and (min-width:1080px) {
    .masonryholder {
        column-count: 3;
    }
}

@media screen and (min-width:1200) {
    .masonryholder {
        column-count: 4;
    }
}