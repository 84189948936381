.mbr-date-header { 
    position: relative;
    vertical-align: initial;
}
.mbr-date-header-icon {
    top: 0.10em;
    margin-right: 5px;
}
.mbr-date-header-clock {
    margin-left: 1em;
}
.mbr-date-paper {
    color: #fff;
    padding: 7px;
    margin-bottom: 1em;
}
.mbr-box-loading { margin: 30px 0;}