.mbr-date-header { 
    position: relative;
    vertical-align: initial;
}
.mbr-date-header-icon {
    top: 0.10em;
    margin-right: 5px;
}
.mbr-date-header-clock {
    margin-left: 1em;
}
.mbr-date-paper {
    color: #fff;
    padding: 7px;
    margin-bottom: 1em;
}
.mbr-box-loading { margin: 30px 0;}
.masonryholder {
    /*margin: 10px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 4px;*/
    column-count: 4;
    grid-column-gap: 20px;
    column-gap: 20px;
    max-width: 1200px;
    /*margin: 0 auto;*/
}

.masonryblocks {
    display: inline-block;
    padding: 10px;
    margin: 0 0 10px;
    width: 100%;
    box-sizing: border-box;
}

.masonryblocks img {
    width: 100%;
    border-radius: 4px 4px 0 0;
}
.caption {
    background: #FE7820;
    margin-top: -6px;
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
}

.bl_masonryblocks {
    transition-duration: 0.3s;
    transition-property: box-shadow;
    border-radius: 4px;
}

.bl_masonryblocks:hover {
    cursor: pointer;
    box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
    border-radius: 4px;
}

.imageTitle {
    font-size: 1.5rem;
}

@media screen and (max-width:768px) {
    .masonryholder {
        column-count: 1;
    }
}

@media screen and (min-width:769px) {
    .masonryholder {
        column-count: 2;
    }
}

@media screen and (min-width:1080px) {
    .masonryholder {
        column-count: 3;
    }
}

@media screen and (min-width:1200) {
    .masonryholder {
        column-count: 4;
    }
}

a {
    text-decoration: none;
}
